import React, { FC, useState } from 'react';

import s from './Projects.module.scss';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Button, Modal } from '../../../components';
import { ArrowNextIcon, ArrowPrevIcon } from '../../../assets/images/svg';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import ModalProject from '../../../components/modal/modal-project/ModalProject';

import binobiDesktop from '../../../assets/images/projects/binobi/desktop.jpg';
import binobiMobile from '../../../assets/images/projects/binobi/mobile.jpg';
import binobiSubImage from '../../../assets/images/projects/binobi/subimage.png';
import binobiLogo from '../../../assets/images/projects/binobi/logo.svg';
import chiksDesktop from '../../../assets/images/projects/chiks/desktop.jpg';
import chiksMobile from '../../../assets/images/projects/chiks/mobile.jpg';
import chiksSubImage from '../../../assets/images/projects/chiks/subimage.png';
import bearDesktop from '../../../assets/images/projects/bear/desktop.jpg';
import bearMobile from '../../../assets/images/projects/bear/mobile.jpg';
import bullsDesktop from '../../../assets/images/projects/bulls/desktop.jpg';
import bullsMobile from '../../../assets/images/projects/bulls/mobile.jpg';
import cyberDesktop from '../../../assets/images/projects/cyber/desktop.jpg';
import cyberMobile from '../../../assets/images/projects/cyber/mobile.jpg';
import girlsDesktop from '../../../assets/images/projects/girls/desktop.jpg';
import girlsMobile from '../../../assets/images/projects/girls/mobile.jpg';
import vpayDesktop from '../../../assets/images/projects/vpay/desktop.jpg';
import vpayMobile from '../../../assets/images/projects/vpay/mobile.jpg';

interface IProjects {}
export interface IProjectsItem {
	image: string;
	link: string;
	title: string;
	text: string;
	logo?: string;
	logoText?: string;
	imageMobile: string;
	subImage: string;
	implementedTasks: string[];
}

const Projects: FC<IProjects> = () => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery('991.98');

	const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);

	const openModal = (index: number) => {
		setOpenModalIndex(index);
	};

	const closeModal = () => {
		setOpenModalIndex(null);
	};

	const items: IProjectsItem[] = [
		{
			link: 'https://binobi.com/',
			image: binobiDesktop,
			title: t('project1_title'),
			text: t('project1_text'),
			logo: binobiLogo,
			imageMobile: binobiMobile,
			implementedTasks: [
				'connecting smart contracts',
				'mysql database connection',
				'development of a unique design',
				'adaptive layout using react.js',
				'web3 integration',
			],
			subImage: binobiSubImage,
		},
		{
			link: '/',
			image: chiksDesktop,
			title: 'The Space Chicks',
			text: 'A group of girls, due to unbearable conditions on earth, sets off to conquer space. Before the start of the expedition, they were photographed. Now the only way to communicate with them is the blockchain.',
			imageMobile: chiksMobile,
			implementedTasks: [
				'development of a unique design',
				'adaptive layout using react.js',
				'development of a smart contract in the Ethereum network',
				'web3 integration',
			],
			subImage: chiksSubImage,
		},
		{
			link: '/',
			image: bearDesktop,
			title: 'Bear Market Bar',
			text: `
            <p>
            The bears are waiting for the bear market in their bar. They drink, fight and play their NFT games on Solana to earn money for whiskey.
            </p>
            <p>
            Limited collection of 5000 unique NFT Bears on the Solana blockchain. The main part of the Play to Earn BMB Games.
            </p>
            <p>
            Only Bear owners will have access to BMB games, drops and other perks.
            </p>
            <p>
            5% of the total amount of BMB coins will be distributed to NFT holders as a drop.
            </p>
            `,

			imageMobile: bearMobile,
			implementedTasks: [
				'development of a unique design',
				'adaptive layout using react.js',
				'development of a smart contract in the Solana network',
				'web3 integration',
			],
			subImage: chiksSubImage,
		},
		{
			link: '/',
			image: bullsDesktop,
			title: 'Evolved Bulls Are Here',
			text: 'The WOTO team originally came together in the BAYK community, and we were amazed at the strength of a community like theirs. We got together to brainstorm some exciting community activities and came up with the idea for Bulls on the Block, the first wallet NFT community. Since then, our vision of combining the beautiful art of NFTs with DeFi infrastructure has grown significantly.',
			imageMobile: bullsMobile,

			implementedTasks: [
				'development of a unique design',
				'adaptive layout using react.js',
				'development of a smart contract in the Ethereum network',
				'web3 integration',
			],
			subImage: chiksSubImage,
		},
		{
			link: '/',
			image: cyberDesktop,
			title: 'Cyber City Girls Club',
			text: 'Cyber City Girls Club is a collection of 5555 unique Cyber Girl NFTs. Each NFT Girl is completely unique and randomly generated.',
			imageMobile: cyberMobile,

			implementedTasks: [
				'development of a unique design',
				'adaptive layout using react.js',
				'development of a smart contract in the Ethereum network',
				'web3 integration',
			],
			subImage: chiksSubImage,
		},
		{
			link: '/',
			image: girlsDesktop,
			title: 'Solana Monkeys Club',
			text: 'SMC is a set of monkey NFTs on the Solana blockchain. Kiddo Monkeys is a GEN 0 collection of 6939 algorithmically generated monkeys. The price of each KIDDO will be 0.1 SOL without an anchor curve. Each monkey is uniquely generated from several traits associated with a certain rarity. These monkeys are stored in the IPFS decentralized storage. KIDDO will be available immediately after minting and can be viewed in phantom, solflare or similar wallets.',
			imageMobile: girlsMobile,

			implementedTasks: [
				'development of a unique design',
				'adaptive layout using react.js',
				'development of a smart contract in the Ethereum network',
				'web3 integration',
			],
			subImage: chiksSubImage,
		},
		{
			link: '/',
			image: vpayDesktop,
			title: 'Get Your Payments',
			text: 'SMC is a set of monkey NFTs on the Solana blockchain. Kiddo Monkeys is a GEN 0 collection of 6939 algorithmically generated monkeys. The price of each KIDDO will be 0.1 SOL without an anchor curve. Each monkey is uniquely generated from several traits associated with a certain rarity. These monkeys are stored in the IPFS decentralized storage. KIDDO will be available immediately after minting and can be viewed in phantom, solflare or similar wallets.',
			imageMobile: vpayMobile,

			implementedTasks: [
				'development of a unique design',
				'adaptive layout using react.js',
				'development of a smart contract in the Ethereum network',
				'web3 integration',
			],
			subImage: chiksSubImage,
		},
	];

	return (
		<section className={s.section}>
			<div className='container'>
				<div className={s.wrapper}>
					<div className={s.head}>
						<h2
							className={clsx(s.title, 'title')}
							dangerouslySetInnerHTML={{
								__html: t('projects_title'),
							}}
						/>
						<div className={s.progressbar}>
							<div className='projects-progressbar'></div>
						</div>
						{!isMobile && (
							<div className={s.arrows}>
								<button className={clsx(s.arrow, 'projects-arrow-prev')}>
									<ArrowPrevIcon />
								</button>
								<button className={clsx(s.arrow, 'projects-arrow-next')}>
									<ArrowNextIcon />
								</button>
							</div>
						)}
					</div>

					<Swiper
						className={s.slider}
						spaceBetween={20}
						slidesPerView={1.07}
						loop={true}
						speed={1000}
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
						pagination={{
							el: '.projects-progressbar',
							type: 'progressbar',
						}}
						navigation={{ nextEl: '.projects-arrow-next', prevEl: '.projects-arrow-prev' }}
						modules={[Pagination, Navigation, Autoplay]}
						breakpoints={{
							992: {
								spaceBetween: 16,
								slidesPerView: 2.5,
							},
						}}
					>
						{items &&
							items.map((item, index) => (
								<SwiperSlide key={index} className={s.item}>
									<div className={s.item__image}>
										<img src={item.image} alt={item.title} />
									</div>
									<div className={s.item__wrapper}>
										<div className={s.item__title}>{item.title}</div>
										<div className={s.item__text} dangerouslySetInnerHTML={{ __html: item.text }} />
										<Button
											onClick={() => openModal(index)}
											variant={'white'}
											className={s.item__button}
										>
											{t('view-project')}
										</Button>
									</div>
								</SwiperSlide>
							))}
					</Swiper>
				</div>
			</div>
			{openModalIndex !== null && (
				<Modal isOpen={true} onClose={closeModal}>
					<ModalProject data={items[openModalIndex]} />
				</Modal>
			)}
		</section>
	);
};

export default Projects;
